import { Component, OnInit } from '@angular/core';
import {SwiperConfig} from 'ngx-swiper-wrapper';

@Component({
  selector: 'app-padraologistico',
  templateUrl: './padraologistico.component.html',
  styleUrls: ['./padraologistico.component.scss']
})
export class PadraologisticoComponent implements OnInit {

  swiperConfig: any = {
    direction: 'horizontal',
    pagination: true,
    navigation: false,
    autoplay: true,
    loop: true,
    slidesPerView: 1,
    speed: 5000
  }

  constructor() { }

  ngOnInit() {
  }

}
