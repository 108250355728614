import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {IndexComponent} from './pages/index/index.component';
import {PadraologisticoComponent} from './pages/cases/padraologistico/padraologistico.component';
import {InfomapComponent} from './pages/cases/infomap/infomap.component';


const routes: Routes = [
  {
    path: '',
    component: IndexComponent
  },
  {
    path: 'cases/padrao-logistico',
    component: PadraologisticoComponent
  },
  {
    path: 'cases/infomap',
    component: InfomapComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
