import { Component, OnInit } from '@angular/core';
// import Swiper JS
import Swiper from 'swiper';
// import Swiper styles
import 'swiper/swiper-bundle.css';
import {SwiperConfig} from 'ngx-swiper-wrapper';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit {

  swiperConfig: any = {
    direction: 'horizontal',
    pagination: true,
    navigation: false,
    autoplay: true,
    loop: true,
    slidesPerView: window.innerWidth < 1000 ? 1 : 6
  }

  constructor() { }

  ngOnInit() {
  }

}
